export default {
  activates_0: '1) Organize and hold semi-annual meetings of Arab and Chinese businessmen under the name of "Arab-Chinese Businessmen\'s Forum" in the Arab countries and Chinese provinces that wish to host such meetings.',
  activates_1: '2) Organizing and holding sectoral meetings in the fields of trade, industry, agriculture, tourism, transport, contracting, banks or any other economic sectors in order to coordinate Arab and Chinese businessmen working in these fields.',
  activates_2: '3) Organizing conferences and business meetings (B2B) and permanent exhibitions and seasonal in the Arab countries and provinces of China.',
  activates_3: '4) Preparation of specialized economic studies.',
  activates_4: '5) Establishment and management of commercial complexes and showrooms in Arab and Chinese provinces.',
  activates_5: '6) To act as a link between ministries, institutions, organizations and Arab and Chinese organizations to facilitate economic relations and overcome obstacles to the flow of money, people and goods between the Arab countries and China.',
  activates_6: '7) To act as representative of Arab and Chinese ministries, institutions, organizations and organizations when dealing with similar foreign institutions',
  activates_7: '8) Participation in Arab, Chinese and international meetings related to the activities of Arab and Chinese businessmen.',
  activates_8: '9) Open channels of cooperation with regional economic groups to achieve different gains for the Arab-Chinese business community',
  activates_9: '10) Organizing public and sectoral training courses and seminars in the Arab countries, the Chinese provinces or in the world capitals to show the role and importance of the Arab-Chinese Business Forum as a sponsor of the activities of Arab and Chinese businessmen.',
  activates_10: '11) Issuing the Arabic-Chinese Business Directory periodically in Arabic, Chinese and English, and issuing periodic and information bulletins.',
  activates_11: '12) Establishment of specialized specialist portal.',
  activates_12: '13) Consider all commercial disputes between the Arab and Chinese companies or between them and others, whether they are natural or legal persons.',
  activates_13: '14) Providing specialized legal consultations in commercial contracts.',
  activates_14: '15) Formulation and writing of local, regional and international commercial contracts.',
  activates_15: '16) Legal monitoring and follow-up of the implementation of commercial contracts.',
  arabAfrican_0: 'Forum Goals:',
  arabAfrican_1: 'The Forum aims to create interaction and strenathen ties, cooperation and coordination between Arab businessmen andChinese businessmen from various economic sectors to promote the Arab-Chinese economic integration in order to achieve the folowing obiectives:',
  arabAfrican_2: '1) Documenting and developing economic relations between Arab and Chinese businessmen within the framework ofthe Silk Road Initiative.',
  arabAfrican_3: '2) lncrease the volume of trade exchange between the Arab countries and China, and discuss the programs andmechanisms that achieve this. ldentify obstacles, and develop practical solutions that help to remove them.',
  arabAfrican_4: '3) Cooperate with the Arab and Chinese institutions concerned with the development of the inter-Arab Arab trade andbenefit from the programs of these financial and technical institutions and emphasize the importance of their role inpromoting joint Arab-Chinese economic work.',
  arabAfrican_5: '4) Promote joint Arab-Chinese projects within a long-term integration framework',
  arabAfrican_6: '5) Presenting a series of special offers to the investment environment in China, industrial experiences and the successfulcorporate path, and benefiting from experiences and exchange of experiences among the participants.',
  arabAfrican_7: '6) Creating a typical business environment and opening new horizons for Arab and Chinese business men and women.',
  arabAfrican_8: '7) Develop pioneering ideas that will benefit the Arab economy by building joint projects and developing industries.',
  arabAfrican_9: '8) Organizing economic activities, activating business, investment opportunities, partnership and investment.',
  general_01: 'general secretaiat',
  general_02: `Kingdom of Saudi Arabia - State of the United Arab Emirates - Sultanate of Oman - Kingdom of
      Bahrain - State of Qatar - Republic of Iraq - State of Lebanon - State of Palestine - State of
      Libya Kingdom of Morocco - People's Democratic Republic of Algeria - People's Republic of
      China - Republic of Ukraine`,
  ContactUs_01: 'Presideency the Federation:',
  ContactUs_02: 'Egypt',
  ContactUs_03: 'General Secretariat headquarters:',
  ContactUs_04: 'Khartoum City - Republic of Sudan',
  ContactUs_05: 'Contact name:',
  ContactUs_06: 'E-Mail:',
  ContactUs_07: 'Telephone:',
  ContactUs_08: 'Website:',
  ContactUs_09: 'china offices:',
  ContactUs_10: `Xi'an, Shaanxi Province, China`,
  Establishment_01: `The Union is an international Arab organization that operates under the name “The Union of
  Arab Exporters and Importers.” It is one of the specialized Arab unions operating within the
  scope of the Council of Arab Economic Unity, in accordance with the Basic Provisions Agreement
  ratified by the Arab parliaments, and their Majesties and Excellencies the Kings and Heads of
  Arab States. The decision to join the union with the Council of Arab Economic Unity was issued
  during the eighty-third regular session held in Cairo on: 7/6/2006, and the decision to join
  was issued No.: 1310/D83 issued on: 7/6/2006`,
  Establishment_02: 'Founding of the Federation:',
  Establishment_03: `Founding of the Federation The Federation was founded by Mrs. Amal Hassan Zaki, and its
  establishment was announced on: 25/12/2005`,
  genral_01: 'MOSAD ABDELHAMID RASHED',
  genral_02: `Secretary General of the Arab Chamber of Commerce for Import and Export Secretary General of
the Arab Africa China Business Forum Siag Chairman of Arab Automobile Manufacturing Company
SHARKEYAT President of Family Association Advisor to the General Union of Plastic
Manufacturers Founder and Chairman of the Arab Center for the Information Economy`,
  Media_01: 'video',
  Media_02: 'photo',
  Presideency_01: 'Who is amal:',
  Presideency_02: `Qualification Bachelor of Financial and Commercial Transactions, Cairo University 1996 Positions: Goodwill Ambassador for Peace, Health and Human Rights (Canadian Institute) Chairman of the Board of Directors of Nile Marketing and Commercial Investment Company S.A.E Social Work Chairman of the Board of Directors of the Association of Prisoners and their Families, Cairo Arab Economic Work for Specialized Unions Chairman of the Union of Arab Exporters and Importers Member of the Board of Directors of the General Union of Plastic Producers and Manufacturers in the Arab World, affiliated to the Council of Economic Unity (League of Arab States) since 5/14/1998`,
  Presideency_03: `The Union is an international Arab organization that operates under the name “The Union of Arab Exporters and Importers.” It is one of the specialized Arab unions operating within the scope of the Council of Arab Economic Unity, in accordance with the Basic Provisions Agreement ratified by the Arab parliaments, and their Majesties and Excellencies the Kings and Heads of Arab States. The decision to join the union with the Council of Arab Economic Unity was issued during the eighty-third regular session held in Cairo on: 7/6/2006, and the decision to join was issued No.: 1310/D83 issued on: 7/6/2006`,
  services_01: '1. Developing and consolidating economic relations between Arab countries. ',
  services_02: '2. Supporting and developing the investment sector in the Arab countries. ',
  services_03: '3. Supporting and developing the Arab economic sectors using modern management and production methods and technology. ',
  services_04: '4. Develop and develop trade exchange between Arab countries and friendly countries. ',
  services_05: ' 5. Create e-commerce websites. ',
  services_06: '6. Settling advanced technological industries in the Arab countries. ',
  services_07: ' 7. Transfer of modern technology. ',
  services_08: '8. Establishment and management of Arab companies. ',
  services_09: ' 9. Establishing and managing banks and commercial finance institutions. ',
  services_10: ' 10.Investment management in new, existing and faltering projects',
  services_11: ' 11.Establishing and managing international arbitration centers. ',
  services_12: '12.Establishing and managing international research and studies centers ',
  services_13: '13.Establishing close relations with governments, Arab unions, bodies and institutions. ',
  services_14: "14.Establishing cooperation relations with foreign bodies related to the Federation's field and activity. ",
  services_15: ' 15.Establishment and management of a specialized quality center. ',
  services_16: ' 16.Cooperation and participation with international quality centers. ',
  services_17: ' 17.Establishing and managing specialized international institutes, universities and academies. ',
  services_18: '18.Signing, managing and marketing commercial agencies. ',
  services_19: ' 19.Preparing Arab and international market studies. ',
  services_20: '20.Preparing indicators and marketing and economic data on Arab and international markets. ',
  services_21: ' 21.Organizing and managing exhibitions of products and production requirements. ',
  services_22: '22.Organizing and managing conferences, symposia and business forums (B2B). ',
  services_23: ' 23.Issuing magazines, bulletins, periodicals and specialized economic media. ',
  services_24: '24.Issuing the Arab International Exporters and Importers Directory. ',
  home_title: 'Council of Arab Economic Unit',
  home_title1: 'Arab Exporter & Importer Union',
  router_link_01: ' Arab fund',
  router_link_02: 'Arab export',
  router_link_03: 'and import bank',
  router_link_04: 'Bee financial',
  router_link_05: 'service group',
  router_link_06: 'Online',
  router_link_07: 'Exhiptions',
  footer_01: 'Introduction',
  footer_02: 'About Us',
  footer_03: 'User Agreement',
  footer_04: 'Logistics Policy',
  footer_05: 'Return Policy',
  footer_06: 'Safety and security',
  footer_07: 'Import and export',
  footer_08: 'Partners',
  footer_09: 'Find Orders',
  footer_10: 'Find merchants',
  footer_11: 'Contact Us',
  footer_12: 'Telephone',
  footer_13: 'Address:Longchang Technology Building',
  footer_14: ', No. 6, Zhongguancun, Haidian District, Beijing',
  footer_15: 'Pay Methods',
  footer_16: '© Copy Right: Arab Exporter & lmporter Union',
  menu_text_01: 'Main',
  menu_text_02: 'services',
  menu_text_03: 'Activates',
  menu_text_04: 'Media',
  menu_text_05: 'News',
  menu_text_06: 'Contact  Us',
  menu_text_07: 'Arab-African-Chinese Business Forum',
  menu_text_08: 'About us',
  menu_left_text_01: 'Presideency',
  menu_left_text_02: 'Founder',
  menu_left_text_03: 'Regional office',
  menu_left_text_04: 'General secretaiat',
  menu_left_text_05: 'Board of general secretaiat',
  menu_left_text_06: 'Establishment',
  menu_left_text_07: 'Organized structure',
  menu_left_text_08: 'Union board',
  aboutUs_01: `Union of Arab Exporters and Importers - one of the specialized qualitative Arab unions
      operating within the scope of the Council of Arab Economic Unity, in accordance with the Basic
      Provisions Agreement ratified by Arab parliaments and Their Majesties and Excellencies, Kings
      and Heads of Arab States , the founder of the Union, Mrs. Amal Hassan Zaki Hassan, and the
      decision to join the Union to the Council of Arab Economic Unity was issued during the
      eighty-third regular session held in Cairo on: 7/6/2006, and the accession decision No.:
      1310/D 83 was issued.`,
  back_lang: 'back',
  'UnionBoard.8Gme5mfj': 'Arab Federation of Importers and Exporters',
  'UnionBoard.HRBBTx53': 'Council members',
  'UnionBoard.86nSA4X3': 'Chairman of the Board of Directors',
  'UnionBoard.DD6jetpk': 'secretariat',
  'UnionBoard.CxFZtH5K': 'department',
  'UnionBoard.GJ4d367G': 'Research',
  'UnionBoard.t6n5NAZR': 'Training and in training',
  'UnionBoard.cr5eYhbx': 'Trade',
  'UnionBoard.taabRmfh': 'investment',
  'UnionBoard.hPCbRnc8': 'service',
  'UnionBoard.myYhdhpZ': 'Industry',
  'UnionBoard.6icpnnXY': 'Agriculture',
  'UnionBoard.dj8MQDea': 'international relation',
  'UnionBoard.sB45NFYz': 'project',
  'UnionBoard.3SCMjAWa': 'Arab Investment Fund',
  'UnionBoard.Zzhx7n8E': 'Electronic store',
  'UnionBoard.Nt46ZsF8': 'Arab Export Bank',
  'UnionBoard.DzbsZaYA': 'Arab E-commerce Company',
  'UnionBoard.mXMrirBt': 'Arab Financial Services Company',
  'UnionBoard.3YTXDC6E': 'Arab Logistics Service Company',
  'UnionBoard.4maA4Rw8': 'Arab Sports Investment Company',
  'UnionBoard.QkiRK6Dk': 'Arab Academy of Information Economics',
  'UnionBoard.GP3KYx66': 'International Mediation, Arbitration, and Mediation Center',
  'UnionBoard.2X4sCxRA': 'Arab Economic Information Center',
  'UnionBoard.HdJXEwKW': 'Arab Training Center',
  'UnionBoard.jwisQzdk': 'Arab African Chinese Business Forum',
  'UnionBoard.HZH4KHPF': 'assistant secretary',
  'UnionBoard.aYSMFCFW': 'Planning and follow-up actions',
  'UnionBoard.8bWX2tsk': 'Membership Qualification',
  'UnionBoard.r8wApYWd': 'human development ',
  'UnionBoard.wA7ZcwpQ': 'account',
  'UnionBoard.GnxiyPSw': 'Journalism',
  'UnionBoard.YiSmrWAN': 'Technical information',
  'UnionBoard.Dd6DPTQe': 'Exhibition and Conference Management Department',
  'UnionBoard.QQG6Dw6f': 'Management and Administration',
  'UnionBoard.5zbApfbT': 'representative office',
  'UnionBoard.czRwXAsE': 'Saudi Arabia',
  'UnionBoard.YHwPEF2J': 'Qatar',
  'UnionBoard.B4PfdYR6': 'Iraq',
  'UnionBoard.rsSSnCKZ': 'Bahrain',
  'UnionBoard.CDGkeN2N': 'Yemen',
  'UnionBoard.zmJ8CsMt': 'Croatia',
  'UnionBoard.AYiMiPe4': 'Palestine',
  'UnionBoard.6A4xh2Qa': 'The United Arab Emirates',
  'UnionBoard.kpQHnJcT': 'Morocco',
  'UnionBoard.fGBnKNnD': 'Algeria',
  'UnionBoard.85YXAe3n': 'Kuwait',
  'UnionBoard.TdWtT3M8': 'Lebanon',
  'UnionBoard.KWmHpA63': 'Oman',
  'UnionBoard.JzWxe6Ry': 'Mauritania',
  'UnionBoard.5b3i8Bek': 'regional office',
  'UnionBoard.tZPxXyAC': 'Egypt',
  'UnionBoard.BpzzitGH': 'Syria',
  'UnionBoard.fT3S2RMz': 'Jordan',
  'UnionBoard.EtGHPYN7': 'Sudan',
  'UnionBoard.PisFEbWD': 'mechanism',
  'UnionBoard.amEFsjbh': 'wise man',
  'UnionBoard.4KpX5MDN': 'leader',
  'UnionBoard.QsMpJ8mk': 'scientist',
  'UnionBoard.a3CKYtfA': 'puber',
  'UnionBoard.d4cbEAbE': 'human rights',
  'UnionBoard.sH4TDWRi': 'adviser',
  'UnionBoard.ejw7h5WQ': 'expert',
  'UnionBoard.jhQtbDj4': 'pioneer',
  'UnionBoard.e7jPskzH': 'woman',
  'boardOfGeneral.Tc8smGQJ': 'secretariat',
  'boardOfGeneral.cHMd8eyh': 'department',
  'boardOfGeneral.aAtyCHzJ': 'Research',
  'boardOfGeneral.TZfDZdGi': 'rehabilitation training',
  'boardOfGeneral.tZMShTJP': 'Trade',
  'boardOfGeneral.kfYDrHt4': 'investment',
  'boardOfGeneral.jzcdhcZ6': 'service',
  'boardOfGeneral.45mkGwcr': 'Industry',
  'boardOfGeneral.8G2hTBbA': 'Agriculture',
  'boardOfGeneral.QBWwW66t': 'international relation',
  'boardOfGeneral.6nXKdhHQ': 'project',
  'boardOfGeneral.skbsMhKr': 'Arab Investment Fund',
  'boardOfGeneral.i8aX82MG': 'Electronic store',
  'boardOfGeneral.dZt4pXaa': 'Arab Export Bank',
  'boardOfGeneral.3DHE8KdD': 'Arab E-commerce Company',
  'boardOfGeneral.7GcBemw7': 'Arab Financial Services Company',
  'boardOfGeneral.hbyXNFD5': 'Arab Logistics Service Company',
  'boardOfGeneral.hM3hHf6z': 'Arab Sports Investment Company',
  'boardOfGeneral.pGRZ2eZt': 'Arab Academy of Information Economics',
  'boardOfGeneral.CSY4GmyT': 'Assist Secretary',
  'boardOfGeneral.tWX4P7KA': 'Planning and follow-up actions',
  'boardOfGeneral.5b8GmMWn': 'Organic',
  'boardOfGeneral.3m6SAMPK': 'human development ',
  'boardOfGeneral.6AfP3xAE': 'account',
  'boardOfGeneral.MydW8WaR': 'Journalism',
  'boardOfGeneral.5EyBZXR5': 'Exhibition and Conference Management Department',
  'boardOfGeneral.K5y6wzMJ': 'Management and Administration',
  'boardOfGeneral.yDfrPhzG': 'device',
  'boardOfGeneral.PDbiYb3W': 'Arab African Chinese Business Forum',
  'boardOfGeneral.nfBhxxJZ': 'International Mediation, Arbitration, and Mediation Center',
  'boardOfGeneral.GPziQXAc': 'Arab Center for Information Economy',
  'boardOfGeneral.yhSbms4S': 'Arab Training Center',
  'boardOfGeneral.5d2bH3QB': 'Knowledge of the club',
  'boardOfGeneral.GTQR8YY6': 'social responsibility'
};