<template>
  <div class="main">
    <header>
      <!-- <div
        class="d-flex align-items-center justify-content-end"
        style="
          width: 100%;
          background: #000;
          margin-bottom: 10px;
          padding: 10px 0;
          padding-right: 30px;
        "
      >
        
      </div> -->
      <div class="content div-flex ac js">
        <div class="div-flex ac">
          <img src="@/assets/img/logo.png" alt="" />
          <div class="text">
            <p class="ai" style="width: 100%; padding-left: 20px">{{ $t('home_title') }}</p>
            <p class="ai" style="width: 100%; padding-left: 20px">{{ $t('home_title1') }}</p>
          </div>
        </div>
        <div class="div-flex ac">
          <SvgIcon name="icons_zh" size="30"></SvgIcon>
          <select
            v-model="langVal"
            class="form-select multiple"
            @change="tabChangeFn"
            style="width: 80px; margin-left: 12px"
          >
            <option value="en">EN</option>
            <!-- <option value="zh">ZH</option> -->
          </select>
        </div>
      </div>
    </header>
    <main>
      <div class="video">
        <div class="video-background">
          <video muted id="myVideo" ref="videoRef" :poster="videoPoster" controls>
            <source :src="videoSrc" type="video/mp4" />
          </video>
          <canvas id="canvasId" width="640" height="360" style="display: none"></canvas>
          <div class="btnBox div-flex">
            <div
              class="btn"
              :class="{ active_video_btn: activeVideoBtn == index }"
              v-for="(item, index) in videoList"
              :key="index"
              @click="tabVideoFn(index)"
            >
              {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative; top: -40px">
        <nav>
          <div class="content d-flex flex-row nav">
            <div class="nav_item bg1">
              <RouterLink
                :to="{ path: '/arabFund' }"
                style="color: #fff; width: 100%; height: 100%"
                class="div-flex ac"
              >
                <img
                  style="margin: 0 13px 0 22px"
                  src="@/assets/img/regional-office_07.png"
                  alt=""
                />
                {{ $t('router_link_01') }}
              </RouterLink>
            </div>
            <div class="nav_item bg2" style="z-index: 21">
              <RouterLink
                :to="{ path: '/arabExportAndImportBank' }"
                style="color: #fff; width: 100%; height: 100%"
                class="div-flex ac"
              >
                <img
                  style="margin: 0 13px 0 22px"
                  src="@/assets/img/regional-office_09.png"
                  alt=""
                />
                <div>
                  <p style="line-height: 28px">
                    {{ $t('router_link_02') }}
                  </p>
                  <p style="line-height: 28px">
                    {{ $t('router_link_03') }}
                  </p>
                </div>
              </RouterLink>
            </div>
            <div class="nav_item bg3">
              <div
                class="nav_item_unlike"
                @click="toLinkFn('http://shop.arabimexports.org/')"
              ></div>
            </div>
            <div class="nav_item bg1">
              <RouterLink
                :to="{ path: '/beeFinancialServiceGroup' }"
                style="color: #fff; width: 100%; height: 100%"
                class="div-flex ac"
              >
                <img
                  style="margin: 0 13px 0 22px"
                  src="@/assets/img/regional-office_11.png"
                  alt=""
                />
                <div>
                  <p style="line-height: 28px">
                    {{ $t('router_link_04') }}
                  </p>
                  <p style="line-height: 28px">
                    {{ $t('router_link_05') }}
                  </p>
                </div>
              </RouterLink>
            </div>
            <div class="nav_item bg4 div-flex ac">
              <img style="margin: 0 13px 0 22px" src="@/assets/img/regional-office_14.png" alt="" />
              <div @click="toLinkFn('https://yyzwenbo.com/alabo/index')">
                <p style="line-height: 28px; text-decoration: underline">
                  {{ $t('router_link_06') }}
                </p>
                <p style="line-height: 28px; text-decoration: underline">
                  {{ $t('router_link_07') }}
                </p>
              </div>
            </div>
          </div>
          <div class="content div-flex js menuItemBox">
            <div
              class="menuItem hoverItem center bg-body-tertiary"
              :class="{
                activeItem: activeItem == item.id,
                autoItem: item.id == '008' || item.id == '009'
              }"
              v-for="(item, index) in listDate"
              :key="index"
              @click="menuClickFn(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </nav>
        <div class="content div-flex">
          <div class="main_left div-flex" v-if="activeItem == '001'" :style="{ height: `100%` }">
            <div
              class="left_item"
              :class="{
                active_left_item: activeLeftItem == item.id
              }"
              v-for="item in mainList"
              :key="item.id"
              @click="leftTabFn(item)"
            >
              <span
                :class="`iconfont icon-${item.icon}`"
                style="display: block; margin: 0 12px; font-size: 18px"
              ></span>
              <div class="div-flex js" style="width: 100%">
                <p style="text-align: left">{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div
            class="main_right"
            ref="mainRightRef"
            :class="{ main_right_box: activeItem != '001' }"
          >
            <!-- 纵向 -->
            <Presideency v-if="activeLeftItem == 1" />
            <RegionalOffice ref="RegionalOfficeRef" v-if="activeLeftItem == 2" />
            <UnionBoard v-if="activeLeftItem == 8" />
            <organizedStructure
              v-if="activeLeftItem == 6"
              @toLink="menuClickFn({ id: '008', name: '' })"
            />
            <generalSecretary v-if="activeLeftItem == 3" />
            <estabPage v-if="activeLeftItem == 5" />
            <boardOfGeneral v-if="activeLeftItem == 4" />
            <!-- 横向 -->
            <founder v-if="activeLeftItem == 7" />
            <Services v-if="activeItem == '003'" />
            <Activates v-if="activeItem == '004'" />
            <Media v-if="activeItem == '005'" />
            <News v-if="activeItem == '006'" />
            <ContactUs v-if="activeItem == '007'" />
            <arabAfrican v-if="activeItem == '008'" />
            <aboutUs v-if="activeItem == '009'" />
            <!-- <div v-else style="height: 400px"></div> -->
          </div>
        </div>
      </div>
    </main>
    <footer>
      <div class="footer_content p-2">
        <div class="content d-flex" style="color: #fff">
          <div style="flex: 3">
            <p style="font-weight: 700">{{ $t('footer_01') }}</p>
            <p>
              {{ $t('footer_02') }}
            </p>
          </div>
          <div style="flex: 4">
            <p style="font-weight: 700">{{ $t('footer_03') }}</p>
            <p>{{ $t('footer_04') }}</p>
            <p>{{ $t('footer_05') }}</p>
            <p>{{ $t('footer_06') }}</p>
          </div>
          <div style="flex: 4">
            <p style="font-weight: 700">{{ $t('footer_07') }}</p>
            <p>{{ $t('footer_08') }}</p>
            <p>{{ $t('footer_09') }}</p>
            <p>{{ $t('footer_10') }}</p>
          </div>
          <div style="flex: 6">
            <p style="font-weight: 700">{{ $t('footer_11') }}</p>
            <p>{{ $t('footer_12') }}: 00201110111095</p>
            <p>{{ $t('footer_13') }}</p>
            <p>{{ $t('footer_14') }}</p>
          </div>
          <div style="flex: 4">
            <p style="font-weight: 700; margin-bottom: 12px">{{ $t('footer_15') }}</p>
            <img
              src="@/assets/img/homepage_03.png"
              width="40px"
              style="margin-right: 12px"
              alt=""
            />
            <img
              src="@/assets/img/homepage_08.png"
              width="24px"
              style="margin-right: 12px"
              alt=""
            />
            <img
              src="@/assets/img/homepage_10.png"
              width="26px"
              style="margin-right: 12px"
              alt=""
            />
            <p style="padding-top: 12px">
              <img
                src="@/assets/img/homepage_05.png"
                width="31px"
                style="margin-right: 12px"
                alt=""
              />
              <img
                src="@/assets/img/homepage_13.png"
                width="27px"
                style="border-radius: 50%"
                alt=""
              />
            </p>
          </div>
          <div style="flex: 3"><img src="@/assets/img/logo.png" alt="" width="161px" /></div>
        </div>
      </div>
      <div class="footer">{{ $t('footer_16') }}</div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/views/components/Svgicon/index.vue'
import Presideency from './components/Presideency.vue'
import RegionalOffice from './components/RegionalOffice.vue'
import founder from './components/founder.vue'
import Services from './components/Services.vue'
import Activates from './components/Activates.vue'
import Media from './components/Media.vue'
import News from './components/News.vue'
import ContactUs from './components/ContactUs.vue'
import organizedStructure from './components/organizedStructure.vue'
import UnionBoard from './components/UnionBoard.vue'
import generalSecretary from './components/generalSecretary.vue'
import estabPage from './components/estabPage.vue'
import boardOfGeneral from './components/boardOfGeneral.vue'
import arabAfrican from './components/arabAfrican.vue'
import aboutUs from './components/aboutUs.vue'
import 'vue3-video-play/dist/style.css'
import { onMounted, ref } from 'vue'
import router from '@/router'
import { useAppStore } from '@/store/modules/app'
import { useI18n } from 'vue-i18n'
let { t } = useI18n()
defineOptions({
  name: 'HomeView'
})
const activeVm = undefined
const { changeLanguage, language } = useAppStore()
type MenuList = {
  id: string
  name: string
}
// const httpStr = 'http://file.arab-alabo.com/'
const httpStr = 'https://crmfile.arab-alabo.com/'

const videoList = [
  { id: 1, src: `${httpStr}video/20241209/6zwj5ys34hrkj7arxsky2n2xkw5azhsy.mp4` },
  { id: 2, src: `${httpStr}video/20241210/5rca4hy38yn246tt8st4jt2z5nhn64s7.mp4` },
  { id: 3, src: `${httpStr}video/20241210/cfszkxayh8acdfinb762mfc2xzyrkx7t.mp4` },
  { id: 4, src: `${httpStr}video/20241210/5sj7jtcn6zjcdw72ahepb22h6i6xnn7w.mp4` }
]
const mainRightRef = ref('')
const activeVideoBtn = ref(0)
const videoRef = ref()
const videoSrc = ref<string>()
const videoPoster = ref<any>()
function captureFrame(url: string, current: number | undefined) {
  const video: any = document.createElement('video')
  video.setAttribute('crossOrigin', 'anonymous')
  video.src = url
  const canvas: any = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  video.onloadedmetadata = () => {
    video.currentTime = current //指定视频的低3秒
  }
  video.oncanplay = () => {
    canvas.width = 1920
    canvas.height = 1080
    ctx.drawImage(video, 0, 0, 1920, 1080)
    const imgUrl = canvas.toDataURL('image/png')
    videoPoster.value = imgUrl
  }
}
function tabVideoFn(i: number) {
  captureFrame(videoList[i].src, 5 + i * 5)
  activeVideoBtn.value = i
  console.dir(videoRef.value)

  videoRef.value?.load(videoList[i].src)
  videoSrc.value = videoList[i].src
}
const listDate = ref<MenuList[]>([
  { name: t('menu_text_01'), id: '001' },
  { name: t('menu_text_02'), id: '003' },
  { name: t('menu_text_03'), id: '004' },
  { name: t('menu_text_04'), id: '005' },
  { name: t('menu_text_05'), id: '006' },
  { name: t('menu_text_06'), id: '007' },
  { name: t('menu_text_07'), id: '008' },
  { name: t('menu_text_08'), id: '009' }
])
const activeItem = ref<string>('001')
const activeLeftItem = ref<number>(1)
function toLinkFn(str: string) {
  window.open(str)
}
function tabChangeFn() {
  changeLanguage(langVal.value)
  window.location.reload()
}
type MainList = {
  id: number
  type?: string
  ishiden?: boolean
  children?: boolean
  name: string
  isChidren?: boolean
  icon?: string
}
// 横向切换事件
function menuClickFn(item: MenuList) {
  console.log(item)

  activeLeftItem.value = 1
  activeItem.value = item.id
  if (item.id == '001') {
    activeLeftItem.value = 1
  } else {
    activeLeftItem.value = 0
  }
}
function leftTabFn(item: MainList) {
  activeLeftItem.value = item.id
  if (item.isChidren) {
    return false
  }
  if (item.children) {
    mainList.value.forEach((element) => {
      if (element.type == 'children') {
        element.ishiden = false
      } else {
        element.ishiden = true
      }
    })
  } else {
    mainList.value.forEach((element) => {
      if (element.type == 'children') {
        element.ishiden = true
      } else {
        element.ishiden = false
      }
    })
  }
}
const mainList = ref<MainList[]>([
  // Presideency Regional  office General  secretary       board of
  {
    id: 1,
    name: t('menu_left_text_01'),
    icon: 'zhuxi'
  },
  {
    id: 7,
    name: t('menu_left_text_02'),
    icon: '1189'
  },
  {
    id: 2,
    name: t('menu_left_text_03'),
    icon: '4banshichu'
  },
  {
    id: 3,
    name: t('menu_left_text_04'),
    icon: 'icon85'
  },
  {
    id: 4,
    name: t('menu_left_text_05'),
    icon: 'ic_zhaopin'
  },
  {
    id: 5,
    name: t('menu_left_text_06'),
    icon: 'establishment-sign-o-'
  },
  {
    id: 6,
    name: t('menu_left_text_07'),
    icon: 'zuzhijiagou'
  },

  {
    id: 8,
    name: t('menu_left_text_08'),
    icon: '1189'
  }
])

const langVal = ref<string>('en')

onMounted(() => {
  langVal.value = language || 'en'
  changeLanguage(langVal.value)

  tabVideoFn(0)
})
//emit
</script>

<style lang="scss" scoped>
.content {
  width: 1320px;
  margin: 0 auto;
}

.main {
  width: 100%;
}

header {
  padding: 12px 0;

  .content {
    img {
      width: 150px;
      padding-right: 12px;
      border-right: 1px solid #ccc;
    }
  }

  .text {
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}

main {
  .video {
    position: relative;

    &:hover {
      .btnBox {
        display: flex;
      }
    }

    .btnBox {
      position: absolute;
      bottom: 100px;
      width: 100%;
      text-align: right;
      display: none;
      justify-content: center;

      .btn {
        color: #fff;
        border: 1px solid #ffff;
        width: 12%;
        height: 50px;
        margin-right: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 4px;

        &:hover {
          background: rgba(1, 80, 172, 0.6);
        }
      }

      .active_video_btn {
        background: rgba(1, 104, 221, 0.6);
        color: #fff;
      }
    }
  }

  .video-background {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  #myVideo {
    width: calc(100vw);
    height: calc(100vh - 150px);
    object-fit: cover;
  }

  .main_left {
    width: calc(100% / 8 + 50px);
    height: 100%;
    flex-flow: column;
    background: rgba(181, 181, 181, 0);
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 5px -1px #9d9b9b;
    position: relative;

    .main_left_box {
      position: absolute;
      height: 100%;
    }

    .left_item {
      border-bottom: 1px solid #e6e6e6;
      border-top: none;
      padding: 20px 0;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      border-radius: 3px;

      &:hover {
        color: #fff;
        background: #6fb3ff;
      }
    }

    .left_children {
      padding-left: 40px;
    }

    .active_left_item {
      background: #0168dd;
      color: #fff;
      border: 1px solid #0168dd;
      transition: all 0.5s;
    }

    .isArrow {
      .icon-xialajiantou1 {
        transform: rotate(180deg);
      }
    }

    .icon-xialajiantou1 {
      transform: rotate(0deg);
    }

    .ishiden {
      display: none;
    }
  }

  .main_right {
    width: calc(100% - (100% / 8 + 40px));
  }

  .main_right_box {
    width: 100%;
  }
}

nav {
  padding-top: 40px;
  color: #fff;

  .nav {
    margin-bottom: 12px;
    box-shadow: 0 0 5px 2px #9d9b9b;
  }

  .nav_item {
    cursor: pointer;
    position: relative;
    width: calc(100% / 5);
    height: 100px;
    border-radius: 3px;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.5s;
      z-index: -1;
    }

    // &:hover {
    //   position: relative;
    //   // z-index: 11;
    //   // box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    //   // transform: scale(1.1);
    //   &::after {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
    // box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
  }

  .bg1 {
    font-size: 14px;
    background: linear-gradient(0deg, #6fb3ff, #0168dd);
    box-shadow: 1px 0px 0px 0px rgba(10, 4, 4, 0.35);
    border-right: 1px solid #fff;
  }

  .bg4 {
    font-size: 14px;
    background: linear-gradient(0deg, #6fb3ff, #0168dd);
    box-shadow: 1px 0px 0px 0px rgba(10, 4, 4, 0.35);
  }

  .bg2 {
    background: linear-gradient(0deg, #6fb3ff, #0168dd);
    box-shadow: 1px 0px 0px 0px rgba(180, 107, 6, 0.35);
  }

  .bg3 {
    width: calc(100% / 5);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 5px;
      top: -25px;
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
      border-radius: 50px;
      z-index: 19;
    }
  }

  .nav_item_unlike {
    position: absolute;
    width: 273px;
    height: 128px;
    bottom: 0;
    padding: 14px 0px 14px 27px;
    left: -0;
    z-index: 30;
    background: url('@/assets/img/regional-office_08.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }

  .menuItemBox {
    flex-flow: wrap;
  }

  .menuItem {
    margin-bottom: 12px;
    width: calc(100% / 9 - 30px);
    border-right: 1px solid #fff;
    padding: 12px;
    background: #eaeaea !important;
    // box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    color: #333;

    &:hover {
      position: relative;
      color: #fff;
      background: #6fb3ff !important;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
        z-index: 10;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #6fb3ff;
      }
    }
  }

  .autoItem {
    flex: auto;
    // margin-left: 12px;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  .activeItem {
    width: calc(100% / 8 + 50px);
    color: #fff;
    background: #0168dd !important;
    transition: all 0.5s;
    border-bottom: 1px solid #0168dd;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      transform: translateX(-50%);
      bottom: -10px;
      z-index: 10;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #0168dd;
    }
  }
}

footer {
  // padding-top: 20px;
  .footer_content {
    width: 100%;
    background: url(@/assets/img/regional-office_06.png) no-repeat;
    background-size: 100%;
    background-position: center center;
    font-size: 14px;

    p {
      padding: 6px;
      line-height: 18px;
    }
  }

  .footer {
    text-align: center;
    padding: 16px 0;
    color: rgba(255, 255, 255, 0.5);
    background: #000;
  }
}

.form-select {
  width: 20%;
}
</style>
