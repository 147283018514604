/**
 * 语言枚举
 */
export const enum LanguageEnum {
  /**
   * 中文
   */
  ZH_CN = 'zh',

  /**
   * 英文
   */
  EN = 'en'
}
