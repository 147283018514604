<template>
  <div
    style="margin-left: 10px; box-shadow: 0 0 5px 2px #e6e6e6; border-radius: 4px"
    class="slide-in-fwd-bottom"
    @click="$emit('toLink')"
  >
    <img src="@/assets/img/os_zh.png" alt="" width="100%" v-if="lang == 'zh'" />
    <img src="@/assets/img/os_en.png" alt="" width="100%" v-if="lang == 'en'" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

defineOptions({
  name: 'ContactUs'
})
const emit = defineEmits(['toLink'])
const lang = ref(window.localStorage.getItem('language') || 'en')
//emit
</script>

<style lang="scss" scoped></style>
