<template>
  <div>
    <div class="div-flex jc" style="padding-top: 20px">
      <div class="btn_box" :class="{ btnActive: isActive == 'video' }" @click="isActiveFn('video')">
        {{ $t('Media_01') }}
      </div>
      <div class="btn_box" :class="{ btnActive: isActive == 'photo' }" @click="isActiveFn('photo')">
        {{ $t('Media_02') }}
      </div>
    </div>
    <div
      class="div-flex js activeBox"
      style="flex-flow: wrap; padding-top: 24px"
      v-if="isActive == 'photo'"
    >
      <div class="card" v-for="(item, index) in lists" :key="item.id">
        <div>
          <img :src="item.src" width="100%" height="270px" style="margin-bottom: 10px" alt="" />
        </div>
        <p class="card_title" style="text-align: center">{{ index + 1 }}</p>
      </div>
    </div>
    <div
      class="div-flex js activeBox"
      style="flex-flow: wrap; padding-top: 24px"
      v-if="isActive == 'video'"
    >
      <div class="card" v-for="(item, index) in lists" :key="item.id">
        <div
          :class="{ videoBox: isActive == 'video' }"
          @click="toPlayFn(item.src)"
          style="height: 180px; overflow: hidden"
        >
          <video id="myVideo" width="100%" ref="videoRef">
            <source :src="item.src" type="video/mp4" />
          </video>
        </div>
        <p class="card_title" style="text-align: center">{{ index + 1 }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
type modlude = Record<string, { default: string }>
const importList: modlude = import.meta.glob('@/assets/img/photo_*.jpg', {
  eager: true
})
const httpStr = 'https://crmfile.arab-alabo.com/'

const videoImgs: modlude = {
  str: { default: `${httpStr}video/20241209/pjps2j32k4rsafjebn6asymrantkzh6d.mp4` },
  str1: { default: `${httpStr}video/20241209/2dbsepkz738ftpwcb6xcpiy4e8mbka5b.mp4` },
  str2: { default: `${httpStr}video/20241209/bsymrbkb775cdbedafww4epi3ih36enb.mp4` },
  str3: { default: `${httpStr}video/20241209/adbdndn7xbst58rnxc6aee74bmf3xa8y.mp4` },
  str4: { default: `${httpStr}video/20241209/h6tmkea84m268hemw7b4zdp67xi5neax.mp4` },
  str5: { default: `${httpStr}video/20241209/j7idxr6mf5dhb382n6i7jmdackjats3j.mp4` },
  str6: { default: `${httpStr}video/20241209/an32fent3bhjzzzr5d5mrm5bwsnzth8e.mp4` }
}
type ListVo = {
  id: number
  title: string
  date: string
  time: string
  address: string
  src: string
}
function toPlayFn(src: string | URL | undefined) {
  window.open(src)
}
const lists = ref<ListVo[]>([])
const isActive = ref<string>('')
type IsActiveFnVo = (str: string) => void
const isActiveFn: IsActiveFnVo = (str: string) => {
  lists.value = []
  isActive.value = str
  let moudel: Record<string, modlude> = { video: videoImgs, photo: importList }
  Object.values(moudel[str]).forEach((ele, index) => {
    lists.value.push({
      id: index,
      title: `[Xi'an Station] Huizhi Quality Improvement: Opening a New`,
      date: '10/22',
      time: '13:00-17:00',
      address: '陕西西安',
      src: ele.default
    })
  })
}
isActiveFn('photo')

defineOptions({
  name: 'MediaPage'
})
//emit
</script>

<style lang="scss" scoped>
.activeBox {
  &::after {
    content: '';
    width: calc(100% / 4 - 20px);
  }
}
.card {
  width: calc(100% / 4 - 20px);
  padding: 0;
  padding-bottom: 10px;
  margin-bottom: 17px;
  background: #fff;
  border-radius: 6px;
  box-shadow: none;
  border: 1px solid #eeeeee;

  .card_title {
    color: #333;
    font-size: 16px;
    padding-left: 12px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .card_date {
    padding-left: 12px;
    color: #ff8d24;
    font-size: 14px;
    margin-bottom: 12px;
  }
  .card_add {
    padding-left: 12px;
    color: #999;
    font-size: 12px;
  }
  .img {
    margin-bottom: 5px;
  }
}
.videoBox {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 96%;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.3;
  }
  &::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('@/assets/img/play_icon.png') no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-color: #fff;
    border-radius: 50%;

    // background: #ff8d24;
  }
}
.btn_box {
  padding: 4px 12px;
  border-radius: 4px;
  background: #979595;
  color: #fff;
  margin-left: 12px;
}
.btnActive {
  color: #fff;
  background: #0168dd;
  border: 1px solid #0168dd;
}
</style>
