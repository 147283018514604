<template>
  <div class="w-1200">
    <div class="btnBox ms-4" @click="backFn" style="margin: 20px">
      <img src="@/assets/img/back_001.png" width="16px" />
      {{ $t('back_lang') }}
    </div>
    <div class="d-flex center">
      <img src="@/assets/img/active_img_001.jpg" width="60%" alt="" style="margin-bottom: 30px" />
    </div>
    <p>
      近日，为充分发挥上海合作组织国家多功能经贸平台推动国际经贸合作和人文交流的桥梁和载体作用，拓展上合与阿拉伯国家之间的经贸合作空间，
      上海合作组织国家多功能经贸平台国际交流中心与阿拉伯进出口商业联合会签署合作备忘录。
    </p>
    <p>
      上海合作组织国家多功能经贸平台总干事、重庆市政协港澳台侨和外事委员会副主任胡开强，
      上海合作组织国家多功能经贸平台秘书长文继旭，阿拉伯进出口商业联合会秘书长摩萨德先生，主席艾迈勒·哈桑女士等出席签约仪式。
    </p>
    <p>
      根据协议，双方将积极推动包括中阿在内的上合及其他国家和地区的企业在交通运输、
      国际物流、能源、金融、国际贸易、工业技术等领域国际合作，为深化双边投资、技术及产业合作创造有利条件。
    </p>
  </div>
</template>

<script setup lang="ts">
import router from '@/router'

defineOptions({
  name: ''
})
function backFn() {
  router.go(-1)
}
//emit
</script>

<style lang="scss" scoped>
.w-1200 {
  margin: 0 auto;
  width: 1200px;
  p {
    text-indent: 2em;
    font-size: 16px;
    margin-bottom: 12px !important;
  }
}
.btnBox {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 30px;
  background: #0168dd;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>
