import './assets/main.css'

import { createApp } from 'vue'
import { store } from '@/store'
import App from './App.vue'
import router from './router'
import vueI18n from './lang'
import vue3TreeOrg from 'vue3-tree-org'
import 'vue3-tree-org/lib/vue3-tree-org.css'
import VueDOMPurifyHTML from 'vue-dompurify-html';
// import { Skeleton as vSkeleton, SkeletonItem as vSkeletonItem } from '@/directive/skeleton'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import 'virtual:svg-icons-register'
const app = createApp(App)
// registerDirectives(app)
app.use(vue3TreeOrg)
app.use(VueDOMPurifyHTML)
app.use(store)
app.use(router)
app.use(vueI18n)
app.mount('#app')
