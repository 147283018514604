export default {
  activates_0: '1)在希望主办此类会议的阿拉伯国家和中国省份，以“阿拉伯-中国商人论坛”的名义组织和举行阿拉伯和中国商人的半年度会议。',
  activates_1: '2) 组织和举行贸易、工业、农业、旅游、运输、承包、银行或任何其他经济部门的部门会议，以协调在这些领域工作的阿拉伯和中国商人。',
  activates_2: '3) 在阿拉伯国家和中国各省组织会议和商务会议（B2B）以及常设展览和季节性展览。',
  activates_3: '4) 准备专门的经济研究。',
  activates_4: '5) 在阿拉伯和中国省份建立和管理商业综合体和展厅。',
  activates_5: '6) 作为各部委、机构、组织以及阿拉伯和中国组织之间的纽带，促进经济关系，克服阿拉伯国家和中国之间资金、人员和货物流动的障碍。',
  activates_6: '7) 在与类似的外国机构打交道时，担任阿拉伯和中国部委、机构、组织和组织的代表',
  activates_7: '8) 参加与阿拉伯和中国商人活动有关的阿拉伯、中国和国际会议。',
  activates_8: '9) 与区域经济集团开放合作渠道，为阿中工商界实现不同收益',
  activates_9: '10)在阿拉伯国家、中国各省或世界各国首都组织公共和部门培训课程和研讨会，以展示阿中商业论坛作为阿拉伯和中国商人活动赞助商的作用和重要性。',
  activates_10: '11) 定期发布阿拉伯文、中文和英文的《阿中商务名录》，并定期发布信息公告。',
  activates_11: '12) 建立专门的专家门户网站。',
  activates_12: '13) 考虑阿拉伯和中国公司之间或它们与其他人之间的所有商业纠纷，无论是自然人还是法人。',
  activates_13: '14) 在商业合同中提供专业法律咨询。',
  activates_14: '15) 制定和撰写当地、区域和国际商业合同。',
  activates_15: '16) 对商业合同执行情况的法律监督和后续行动。',
  arabAfrican_0: '论坛目标：',
  arabAfrican_1: '论坛旨在促进阿拉伯商人和来自各经济部门的中国商人之间的互动，加强联系、合作与协调，促进阿中经济一体化，以实现以下目标：',
  arabAfrican_2: '1) 在丝绸之路倡议框架内记录和发展阿拉伯和中国商人之间的经济关系。',
  arabAfrican_3: '2) 增加阿拉伯国家与中国之间的贸易往来，并讨论实现这一目标的计划和机制。识别障碍，并制定有助于消除障碍的实用解决方案。',
  arabAfrican_4: '3) 与关注阿拉伯-阿拉伯贸易发展的阿拉伯和中国机构合作，从这些金融和技术机构的计划中受益，并强调它们在促进阿中联合经济工作中的重要作用。',
  arabAfrican_5: '4) 在长期一体化框架内促进阿中联合项目',
  arabAfrican_6: '5)介绍一系列关于中国投资环境、行业经验和成功企业路径的特别优惠，并从参与者之间的经验和经验交流中受益。',
  arabAfrican_7: '6) 为阿拉伯和中国的商界男女创造一个典型的商业环境，开辟新的视野。',
  arabAfrican_8: '7)通过建立联合项目和发展产业，发展有利于阿拉伯经济的开创性想法。',
  arabAfrican_9: '8) 组织经济活动，激活商业、投资机会、伙伴关系和投资。',
  general_01: '秘书长',
  general_02: `沙特阿拉伯王国-阿拉伯联合酋长国-阿曼苏丹国-
巴林-卡塔尔国-伊拉克共和国-黎巴嫩国-巴勒斯坦国-
利比亚摩洛哥王国-阿尔及利亚民主人民共和国-
中国-乌克兰共和国`,
  ContactUs_01: '联合会主席：',
  ContactUs_02: '埃及',
  ContactUs_03: '总秘书处总部：',
  ContactUs_04: '苏丹共和国喀土穆市',
  ContactUs_05: '联系人姓名：',
  ContactUs_06: '电子邮件：',
  ContactUs_07: '电话:',
  ContactUs_08: '网站:',
  ContactUs_09: '中国办事处:',
  ContactUs_10: `中国陕西省西安市`,
  Establishment_01: `该联盟是一个国际阿拉伯组织，以“阿拉伯联盟”的名义运作
阿拉伯出口商和进口商。”它是阿拉伯国家内部运作的专业工会之一
根据《基本条款协定》，阿拉伯经济统一理事会的范围
经阿拉伯议会及其国王和首脑陛下和阁下批准
阿拉伯国家。决定加入阿拉伯经济统一理事会
2006年7月6日在开罗举行的第八十三届常会期间，决定加入
签发编号：1310/D83签发日期：2006年7月6日`,
  Establishment_02: '联合会成立：',
  Establishment_03: `联合会由Amal Hassan Zaki女士创立
成立日期：2005年12月25日`,
  genral_01: '穆罕默德·阿卜杜勒哈米德皮疹',
  genral_02: `阿拉伯进出口商会秘书长
阿拉伯非洲中国商业论坛Siag阿拉伯汽车制造公司董事长
SHARKEYAT家庭协会主席塑料总工会顾问
制造商阿拉伯信息经济中心创始人兼主席
翻译详解试试AI翻译
笔记`,
  Media_01: '视频',
  Media_02: '图片',
  Presideency_01: '谁是阿玛拉:',
  Presideency_02: `1996年开罗大学金融与商业交易学士学位和平、健康和人权亲善大使(加拿大研究所)尼罗河营销和商业投资公司S.A.E社会工作董事会主席囚犯及其家属协会董事会主席、开罗阿拉伯专门工会经济工作委员会主席阿拉伯进出口商联盟主席阿拉伯世界塑料生产商和制造商总联盟董事会成员;自1998年5月14日起加入经济统一委员会(阿拉伯国家联盟)`,
  Presideency_03: `该联盟是一个国际阿拉伯组织，以“阿拉伯进出口联盟”的名义运作。它是在阿拉伯经济统一委员会范围内按照阿拉伯议会、国王和阿拉伯国家元首陛下和阁下批准的《基本条款协定》运作的专门阿拉伯联盟之一。加入阿拉伯国家联盟的决定于2006年7月6日在开罗举行的第83届常会上发布。: 1310/D83在:7/6/2006发出`,
  services_01: '1. 发展和巩固阿拉伯国家之间的经济关系。 ',
  services_02: '2.支持和发展阿拉伯国家的投资部门。 ',
  services_03: '3. 利用现代管理和生产方法和技术支持和发展阿拉伯经济部门。 ',
  services_04: '4. 发展和发展阿拉伯国家与友好国家之间的贸易往来。 ',
  services_05: ' 5. 创建电子商务网站。 ',
  services_06: '6. 在阿拉伯国家落户先进技术产业。 ',
  services_07: ' 7. 现代技术转让。 ',
  services_08: '8. 阿拉伯公司的设立和管理。 ',
  services_09: ' 9. 设立和管理银行、商业金融机构。 ',
  services_10: ' 10.新项目、现有项目和步履蹒跚的项目的投资管理。',
  services_11: ' 11.设立和管理国际仲裁中心 ',
  services_12: '12.建立和管理国际研究和研究中心 ',
  services_13: '13.与政府、阿拉伯联盟、机构和机构建立密切关系。 ',
  services_14: '14.建立与联合会领域和活动有关的外国机构的合作关系。 ',
  services_15: ' 15.专业质量中心的建立和管理。',
  services_16: ' 16.与国际质量中心的合作与参与。 ',
  services_17: ' 17. 建立和管理专门的国际机构、大学和学院。 ',
  services_18: '18.签约、管理和营销商业机构。',
  services_19: ' 19.准备阿拉伯和国际市场研究。 ',
  services_20: '20.准备阿拉伯和国际市场的指标、营销和经济数据。 ',
  services_21: ' 21.组织和管理产品展示和生产需求。',
  services_22: '22.组织和管理会议、座谈会和商业论坛（B2B）。 ',
  services_23: ' 23.发行杂志、公报、期刊和专业经济媒体。 ',
  services_24: '24.发布阿拉伯国际进出口商名录。',
  home_title: '阿拉伯经济理事会',
  home_title1: '阿拉伯进出口商联盟',
  router_link_01: ' 阿拉伯基金',
  router_link_02: '阿拉伯出口',
  router_link_03: '进口银行',
  router_link_04: '小蜜蜂',
  router_link_05: '服务组',
  router_link_06: '在线的',
  router_link_07: '展厅',
  footer_01: '介绍',
  footer_02: '关于我们',
  footer_03: '用户协议',
  footer_04: '物流政策',
  footer_05: '退货政策',
  footer_06: '安全和安保',
  footer_07: '进出口',
  footer_08: '合作伙伴',
  footer_09: '查找订单',
  footer_10: '寻找商家',
  footer_11: '联系我们',
  footer_12: '电话',
  footer_13: '地址：隆昌科技大厦',
  footer_14: '北京市海淀区中关村6号',
  footer_15: '支付方式',
  footer_16: '© 版权所有：阿拉伯出口商联盟',
  menu_text_01: '主要的',
  menu_text_02: '服务',
  menu_text_03: '活动',
  menu_text_04: '媒体中心',
  menu_text_05: '新闻',
  menu_text_06: '联系我们',
  menu_text_07: '阿拉伯-非洲-中国商业论坛',
  menu_text_08: '关于我们',
  menu_left_text_01: '主席职位',
  menu_left_text_02: '创始人',
  menu_left_text_03: '区域办事处',
  menu_left_text_04: '秘书长',
  menu_left_text_05: '董事会秘书',
  menu_left_text_06: '建立',
  menu_left_text_07: '组织结构',
  menu_left_text_08: '工会委员会',
  aboutUs_01: `阿拉伯进出口联盟-一个专门的阿拉伯质量联盟
根据《基本原则》，在阿拉伯经济统一理事会的范围内开展活动
阿拉伯议会和国王陛下及阁下批准的协定
阿拉伯国家元首，联盟创始人阿迈勒·哈桑·扎基·哈桑夫人，以及
阿拉伯经济统一委员会在会议期间作出了加入联盟的决定
2006年7月6日在开罗举行的第八十三届常会，以及第:
印发了1310/D 83。`,
  back_lang: '返回',
  'UnionBoard.8Gme5mfj': '阿拉伯进出口商联合会',
  'UnionBoard.HRBBTx53': '理事会成员',
  'UnionBoard.86nSA4X3': '董事会主席',
  'UnionBoard.DD6jetpk': '秘书处',
  'UnionBoard.CxFZtH5K': '部门',
  'UnionBoard.GJ4d367G': '研究',
  'UnionBoard.t6n5NAZR': '培训和在培训',
  'UnionBoard.cr5eYhbx': '贸易',
  'UnionBoard.taabRmfh': '投资',
  'UnionBoard.hPCbRnc8': '服务',
  'UnionBoard.myYhdhpZ': '工业',
  'UnionBoard.6icpnnXY': '农业',
  'UnionBoard.dj8MQDea': '国际关系',
  'UnionBoard.sB45NFYz': '项目',
  'UnionBoard.3SCMjAWa': '阿拉伯投资基金',
  'UnionBoard.Zzhx7n8E': '电子商店',
  'UnionBoard.Nt46ZsF8': '阿拉伯出口银行',
  'UnionBoard.DzbsZaYA': '阿拉伯电子商务公司',
  'UnionBoard.mXMrirBt': '阿拉伯金融服务公司',
  'UnionBoard.3YTXDC6E': '阿拉伯物流服务公司',
  'UnionBoard.4maA4Rw8': '阿拉伯体育投资公司',
  'UnionBoard.QkiRK6Dk': '阿拉伯信息经济学院',
  'UnionBoard.GP3KYx66': '国际调解、仲裁和调解中心',
  'UnionBoard.2X4sCxRA': '阿拉伯经济信息中心',
  'UnionBoard.HdJXEwKW': '阿拉伯培训中心',
  'UnionBoard.jwisQzdk': '阿拉伯-非洲-中国商业论坛',
  'UnionBoard.HZH4KHPF': '助理秘书',
  'UnionBoard.aYSMFCFW': '规划和后续行动',
  'UnionBoard.8bWX2tsk': '成员资格',
  'UnionBoard.r8wApYWd': '人类发展',
  'UnionBoard.wA7ZcwpQ': '账户',
  'UnionBoard.GnxiyPSw': '新闻',
  'UnionBoard.YiSmrWAN': '技术信息',
  'UnionBoard.Dd6DPTQe': '展览和会议管理部',
  'UnionBoard.QQG6Dw6f': '管理和行政',
  'UnionBoard.5zbApfbT': '代表处',
  'UnionBoard.czRwXAsE': '沙特',
  'UnionBoard.YHwPEF2J': '卡塔尔',
  'UnionBoard.B4PfdYR6': '伊拉克',
  'UnionBoard.rsSSnCKZ': '巴林',
  'UnionBoard.CDGkeN2N': '也门',
  'UnionBoard.zmJ8CsMt': '克罗地亚',
  'UnionBoard.AYiMiPe4': '巴勒斯坦',
  'UnionBoard.6A4xh2Qa': '阿拉伯联合酋长国',
  'UnionBoard.kpQHnJcT': '摩洛哥',
  'UnionBoard.fGBnKNnD': '阿尔及利亚',
  'UnionBoard.85YXAe3n': '科威特',
  'UnionBoard.TdWtT3M8': '黎巴嫩',
  'UnionBoard.KWmHpA63': '阿曼',
  'UnionBoard.JzWxe6Ry': '毛里塔尼亚',
  'UnionBoard.5b3i8Bek': '区域办事处',
  'UnionBoard.tZPxXyAC': '埃及',
  'UnionBoard.BpzzitGH': '叙利亚',
  'UnionBoard.fT3S2RMz': '约旦',
  'UnionBoard.EtGHPYN7': '苏丹',
  'UnionBoard.PisFEbWD': '机构',
  'UnionBoard.amEFsjbh': '智者',
  'UnionBoard.4KpX5MDN': '领导人',
  'UnionBoard.QsMpJ8mk': '科学家',
  'UnionBoard.a3CKYtfA': '青年人',
  'UnionBoard.d4cbEAbE': '人权',
  'UnionBoard.sH4TDWRi': '顾问',
  'UnionBoard.ejw7h5WQ': '专家',
  'UnionBoard.jhQtbDj4': '先驱',
  'UnionBoard.e7jPskzH': '妇女',
  'boardOfGeneral.Tc8smGQJ': '秘书处',
  'boardOfGeneral.cHMd8eyh': '部门',
  'boardOfGeneral.aAtyCHzJ': '研究',
  'boardOfGeneral.TZfDZdGi': '康复训练',
  'boardOfGeneral.tZMShTJP': '贸易',
  'boardOfGeneral.kfYDrHt4': '投资',
  'boardOfGeneral.jzcdhcZ6': '服务',
  'boardOfGeneral.45mkGwcr': '工业',
  'boardOfGeneral.8G2hTBbA': '农业',
  'boardOfGeneral.QBWwW66t': '国际关系',
  'boardOfGeneral.6nXKdhHQ': '项目',
  'boardOfGeneral.skbsMhKr': '阿拉伯投资基金',
  'boardOfGeneral.i8aX82MG': '电子商店',
  'boardOfGeneral.dZt4pXaa': '阿拉伯出口银行',
  'boardOfGeneral.3DHE8KdD': '阿拉伯电子商务公司',
  'boardOfGeneral.7GcBemw7': '阿拉伯金融服务公司',
  'boardOfGeneral.hbyXNFD5': '阿拉伯后勤服务公司',
  'boardOfGeneral.hM3hHf6z': '阿拉伯体育投资公司',
  'boardOfGeneral.pGRZ2eZt': '阿拉伯信息经济学院',
  'boardOfGeneral.CSY4GmyT': '助力秘书',
  'boardOfGeneral.tWX4P7KA': '规划和后续行动',
  'boardOfGeneral.5b8GmMWn': '有机',
  'boardOfGeneral.3m6SAMPK': '人类发展',
  'boardOfGeneral.6AfP3xAE': '账户',
  'boardOfGeneral.MydW8WaR': '新闻',
  'boardOfGeneral.5EyBZXR5': '展览和会议管理部',
  'boardOfGeneral.K5y6wzMJ': '管理和行政',
  'boardOfGeneral.yDfrPhzG': '装置',
  'boardOfGeneral.PDbiYb3W': '阿拉伯-非洲-中国商业论坛',
  'boardOfGeneral.nfBhxxJZ': '国际调解、仲裁和调解中心',
  'boardOfGeneral.GPziQXAc': '阿拉伯信息经济中心',
  'boardOfGeneral.yhSbms4S': '阿拉伯培训中心',
  'boardOfGeneral.5d2bH3QB': '俱乐部的知识',
  'boardOfGeneral.GTQR8YY6': '社会责任'
}; // 1. 发展和巩固阿拉伯国家之间的经济关系。
// 2. 支持和发展阿拉伯国家的投资部门。
// 3. 利用现代管理和生产方法和技术支持和发展阿拉伯经济部门。
// 4. 发展和发展阿拉伯国家与友好国家之间的贸易往来。
// 5. 创建电子商务网站。
// 6. 在阿拉伯国家落户先进技术产业。
// 7. 现代技术转让。
// 8. 阿拉伯公司的设立和管理。
// 9. 设立和管理银行、商业金融机构。
// 10.新项目、现有项目和步履蹒跚的项目的投资管理。
// 11.设立和管理国际仲裁中心。
// 12.建立和管理国际研究和研究中心。
// 13.与政府、阿拉伯联盟、机构和机构建立密切关系。
// 14.建立与联合会领域和活动有关的外国机构的合作关系。
// 15.专业质量中心的建立和管理。
// 16.与国际质量中心的合作与参与。
// 17.建立和管理专门的国际机构、大学和学院。
// 18.签约、管理和营销商业机构。
// 19.准备阿拉伯和国际市场研究。
// 20.准备阿拉伯和国际市场的指标、营销和经济数据。
// 21.组织和管理产品展示和生产需求。
// 22.组织和管理会议、座谈会和商业论坛（B2B）。
// 23.发行杂志、公报、期刊和专业经济媒体。
// 24.发布阿拉伯国际进出口商名录。