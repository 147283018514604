<template>
  <div class="content">
    <div class="div-flex" style="margin: 20px">
      <div class="btnBox ms-4" @click="backFn">
        <img src="@/assets/img/back_001.png" width="16px" />
        {{$t('back_lang')}} </div>
    </div>
    <img :src="imgSrc" style="display: block; margin: 0 auto; padding: 10px; border: 1px solid #ccc" alt="" />
  </div>
</template>

<script setup lang="ts">
import router from '@/router'
import { useRoute } from 'vue-router'
import arabFund from '@/assets/img/arab_fund.png'
import xmf from '@/assets/img/xmf_03.png'
import atb from '@/assets/img/atb_001.png'
import { onMounted, ref } from 'vue'
const route = useRoute()
const imgSrc = ref()
onMounted(() => {
  console.log(route.name)
  if (route.name == 'arabFund') {
    imgSrc.value = arabFund
  }
  if (route.name == 'beeFinancialServiceGroup') {
    imgSrc.value = xmf
  }
  if (route.name == 'arabExportAndImportBank') {
    imgSrc.value = atb
  }
})
function backFn() {
  router.back()
}

//emit
</script>

<style lang="scss" scoped>
.content {
  width: 1320px;
  margin: 0 auto;
}


.btnBox {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 30px;
  background: #0168dd;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>
